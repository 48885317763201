<template>
  <a-row class="content-fix" type="flex">
    <!-- Spinner for loading -->
    <spinner v-if="loading" />
    <a-col v-show="!loading" class="height100 flex-1" style="padding-top: 30px">
      <span slot="tab"><a-icon type="project" />Редактировать</span>

      <!-- Language Tabs -->
      <a-row align="middle" class="flex-baseline sm-mb">
        <a-col :span="4" class="form_title">Язык:</a-col>
        <a-col :span="12">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <lang-tabs v-model="langTab" class="mb-1x" />
            <a-button v-if="langTab === 'oz'" @click.stop="translitAction">
              Перевести копию на
              {{ langTab === "uz" ? "кирилицу" : "латиницу" }}
            </a-button>
          </a-row>
        </a-col>
      </a-row>

      <!-- Dynamic Form Fields -->
      <a-row
        align="middle"
        :class="{ 'form-group--error': errors[field.key] }"
        class="form-group flex-baseline"
        v-for="field in filteredFields"
        :key="field.key"
      >
        <a-col :span="4" class="form_title">
          <label>{{ $t(field.label) }}</label>
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-if="field.type === 'text'"
            v-model="form[field.key]"
            :placeholder="$t(field.placeholder)"
            @input="validateLength(field.key, field.minLength, field.maxLength)"
          />
          <a-input
            v-else-if="field.type === 'date'"
            type="date"
            v-model="form[field.key]"
            :placeholder="$t(field.placeholder)"
            class="input-date"
          />
          <a-select
            v-else-if="field.type === 'select'"
            v-model="form[field.key]"
            style="width: 100%"
          >
            <a-select-option
              v-for="option in field.options"
              :key="option.value"
              :value="option.value"
            >
              {{ $t(option.label) }}
            </a-select-option>
          </a-select>
          <a-switch
            v-else-if="field.type === 'switch'"
            v-model="form[field.key]"
          />
        </a-col>
      </a-row>

      <!-- Action Buttons -->
      <a-row class="form-buttons__action" style="margin-top: 20px" type="flex">
        <a-col :span="3" class="form_title" />
        <a-col>
          <a-button :disabled="pending" @click="$router.go(-1)">
            <a-icon type="close" />Отмена
          </a-button>
          <a-button
            :loading="pending"
            style="margin-left: 10px"
            type="primary"
            @click="submit"
          >
            <a-icon type="check" />
            Сохранить
          </a-button>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"

export default {
  name: "EditCertificate",
  data() {
    return {
      langTab: $langPrefix, // Current language tab
      form: {}, // Data to populate the form
      fields: [
        {
          key: "title",
          label: "TableTitle",
          type: "text",
          placeholder: "TableTitle",
          isLangSpecific: true // Indicate language-specific fields
        },
        {
          key: "last_update",
          label: "LastUpdateDate",
          type: "text",
          placeholder: "LastUpdateDate",
          isLangSpecific: true
        },
        {
          key: "full_name",
          label: "FormFullName",
          type: "text",
          placeholder: "FormFirstNamePlace",
          minLength: 1,
          maxLength: 255
        },
        {
          key: "passport_number",
          label: "PassportNumber",
          type: "text",
          placeholder: "PassportNumber",
          minLength: 1,
          maxLength: 9
        },
        {
          key: "pinfl",
          label: "pinfl",
          type: "text",
          placeholder: "pinfl",
          minLength: 1,
          maxLength: 14
        },
        {
          key: "gender",
          label: "FormGender",
          type: "select",
          options: [
            { value: 1, label: "FormMale" },
            { value: 2, label: "FormFemale" }
          ]
        },
        {
          key: "cert_number",
          label: "cert_number",
          type: "text",
          placeholder: "cert_number",
          minLength: 1,
          maxLength: 255
        },
        {
          key: "cert_registered_date",
          label: "cert_registered_date",
          type: "date",
          placeholder: "cert_registered_date"
        },
        {
          key: "cert_validity_date",
          label: "cert_validity_date",
          type: "date",
          placeholder: "cert_validity_date"
        },
        {
          key: "licence_number",
          label: "licence_number",
          type: "text",
          placeholder: "licence_number",
          minLength: 1,
          maxLength: 255
        },
        {
          key: "licence_registered_date",
          label: "licence_registered_date",
          type: "date",
          placeholder: "licence_registered_date"
        },
        { key: "is_active", label: "Статус", type: "switch" }
      ],
      errors: {}, // Validation errors
      pending: false,
      loading: true
    }
  },
  computed: {
    filteredFields() {
      // Adjust keys for language-specific fields
      return this.fields.map((field) => {
        if (field.isLangSpecific) {
          return { ...field, key: `${field.key}_${this.langTab}` }
        }
        return field
      })
    }
  },
  async mounted() {
    const id = this.$route.params.id // Get the certificate ID from the route params
    try {
      const res = await this.$api.get(
        `/admin/common/certificate/${id}/update/`
      )
      if (res.status === 200) {
        this.form = cloneDeep(res.data) // Populate the form with data
        console.log(this.form) //
      } else {
        console.error("Error fetching certificate:", res)
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      this.loading = false // Stop showing the loader
    }
  },
  methods: {
    validateLength(key, minlength, maxlength) {
      const value = this.form[key]
      if (value.length > maxlength) {
        this.form[key] = value.substring(0, maxlength) // Trim to maxlength
      }
      if (value.length < minlength) {
        this.errors[key] = `Minimum length is ${minlength} characters.`
      } else {
        this.errors[key] = null // Clear error if valid
      }
    },
    async submit(isFullUpdate = false) {
      if (this.pending) return

      this.pending = true

      try {
        let f = new FormData()

        // Append fields to FormData
        Object.keys(this.form).forEach((key) => {
          const value = this.form[key]
          if (value !== null && value !== undefined) {
            f.append(key, value)
          }
        })

        const id = this.$route.params.id // Get the certificate ID
        const endpoint = `/admin/common/certificate/${id}/update/`
        const res = isFullUpdate
          ? await this.$api.put(endpoint, f) // Use PUT for full updates
          : await this.$api.patch(endpoint, f) // Use PATCH for partial updates

        if (res.status >= 200 && res.status < 300) {
          this.$router.go(-1) // Navigate back on success
        } else {
          console.error("Error updating certificate:", res)
        }
      } catch (error) {
        console.error("Submit Error:", error)
      } finally {
        this.pending = false // Reset pending state
      }
    },
    translitAction() {
      // Transliteration logic (if needed)
      try {
        const from = this.langTab
        const to = this.langTab === "uz" ? "oz" : "uz"
        const methodName = this.langTab === "uz" ? "toKirill" : "toLatin"

        if (this.form[`title_${from}`]) {
          this.form[`title_${to}`] = this[methodName](
            this.form[`title_${from}`]
          )
        }
      } catch (error) {
        console.error("Transliteration Error:", error)
      }
    }
  }
}
</script>

<style>
.ant-modal {
  max-height: 600px;
}
.form-buttons__action {
  margin-top: 20px;
}
.inputNumber::-webkit-outer-spin-button,
.inputNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-date {
  width: 100%; /* Adjust the width (can be 100% or a fixed value) */
  max-width: 300px; /* Limit maximum width for a clean look */
  padding: 5px 10px; /* Add padding for better readability */
  border: 1px solid #d9d9d9; /* Match the design of other inputs */
  border-radius: 4px; /* Smooth corners */
  font-size: 14px; /* Match the font size with your design */
}
</style>
