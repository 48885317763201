<template>
  <a-row class="content-fix" type="flex">
    <spinner v-if="loading" />
    <a-col v-show="!loading" class="height100 flex-1" style="padding-top: 30px">
      <span slot="tab"> <a-icon type="project" />Содержимое </span>
      <!-- lang tabs -->
      <a-row align="middle" class="flex-baseline sm-mb">
        <a-col :span="3" class="form_title"> Язык: </a-col>
        <a-col :span="12">
          <!-- lang translit tabs -->
          <a-row align="middle" class="flex-baseline flex-wrap">
            <lang-tabs v-model="langTab" class="mb-1x" />
            <a-button v-if="langTab === 'oz'" @click.stop="translitAction">
              Перевести копию на
              {{ langTab === "uz" ? "кирилицу" : "латиницу" }}
            </a-button>
          </a-row>
        </a-col>
      </a-row>

      <!-- title -->
      <a-row align="middle" class="flex-baseline">
        <a-col :span="3" class="form_title">Image: </a-col>
        <a-col :span="12" class="sm-mb">
          <div class="form_image">
            <div class="deputy_image">
              <img :src="photo" />
            </div>
            <a-button
              style="font-size: 13px"
              type="primary"
              @click="uploadFile"
            >
              <a-icon style="font-size: 16px" type="upload" /> Upload
              Image</a-button
            >
            <span v-if="!photo" style="padding-left: 8px"
              >Картинка не выбрана</span
            >
            <input
              ref="fileInput"
              class="UploadhideInput"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/svg"
              @change="handleFile"
            />
          </div>
        </a-col>
      </a-row>
      <a-row
        :class="{ 'form-group--error': emptyTitle }"
        align="middle"
        class="flex-baseline"
      >
        <a-col :span="3" class="form_title">
          <label>Ф.И.О</label>
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="form.full_name[langTab]"
            class="form_title-input"
            :placeholder="$t('FormFirstNamePlace')"
          />
        </a-col>
      </a-row>
      <a-row
        :class="{ 'form-group--error': emptyTitle }"
        align="middle"
        class="flex-baseline"
      >
        <a-col :span="3" class="form_title">
          <label>Должность</label>
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="form.position[langTab]"
            class="form_title-input"
            placeholder="Введите должность"
          />
        </a-col>
      </a-row>
      <a-row
        :class="{ 'form-group--error': emptyTitle }"
        align="middle"
        class="flex-baseline"
      >
        <a-col :span="3" class="form_title">
          <label>Год рождения</label>
        </a-col>
        <a-col :span="3" class="sm-mb">
          <a-input
            v-model="form.birth_date"
            type="date"
            class="inputNumber"
            placeholder="Введите год рождения"
          />
        </a-col>
      </a-row>
      <a-row
        :class="{ 'form-group--error': emptyTitle }"
        align="middle"
        class="flex-baseline"
      >
        <a-col :span="3" class="form_title">
          <label>Национальность</label>
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="form.nation[langTab]"
            placeholder="Национальность"
          />
        </a-col>
      </a-row>
      <a-row
        :class="{ 'form-group--error': emptyTitle }"
        align="middle"
        class="flex-baseline"
      >
        <a-col :span="3" class="form_title">
          <label>Telephone</label>
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="form.phone_number"
            v-mask="'+### (##) ###-##-##'"
            placeholder="Введите номер телефона"
            name="phone"
            type="tel"
          />
        </a-col>
      </a-row>
      <a-row
        :class="{ 'form-group--error': emptyTitle }"
        align="middle"
        class="flex-baseline"
      >
        <a-col :span="3" class="form_title">
          <label>Inner Telephone</label>
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="form.inner_number"
            v-mask="'+### (##) ###-##-##'"
            placeholder="Введите внутренний номер"
            name="innerPhone"
            type="tel"
          />
        </a-col>
      </a-row>
      <a-row
        :class="{ 'form-group--error': emptyTitle }"
        align="middle"
        class="flex-baseline"
      >
        <a-col :span="3" class="form_title">
          <label>Email</label>
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="form.email"
            required
            :placeholder="$t('EnterEmail')"
          />
        </a-col>
      </a-row>
      <a-row
        :class="{ 'form-group--error': emptyTitle }"
        align="middle"
        class="flex-baseline"
      >
        <a-col :span="3" class="form_title">
          <label>Адрес</label>
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="form.address[langTab]"
            placeholder="Введите адрес"
          />
        </a-col>
      </a-row>
      <a-row
        :class="{ 'form-group--error': emptyTitle }"
        align="middle"
        class="flex-baseline"
      >
        <a-col :span="3" class="form_title">
          <label>Дни приема</label>
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="form.reception_days[langTab]"
            placeholder="Дни приема"
          />
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline">
        <a-col :span="3" class="form_title"> Позиция: </a-col>
        <a-col :span="2" class="sm-mb">
          <a-input
            v-model="form.order"
            type="number"
            name="position"
            placeholder="Введите позицию"
          />
        </a-col>
      </a-row>
      <a-row style="margin-top: 15px">
        <a-col :span="3" class="form_title"> Статус: </a-col>
        <a-switch v-model="form.is_active" />
      </a-row>
      <!-- <a-row
        :class="{ 'form-group--error': emptyTitle }"
        align="middle"
        class="flex-baseline"
      >
        <a-col :span="3" class="form_title" />
        <a-col :span="12" class="sm-mb">
          <a-checkbox @change="checkChamber"> Вакансия </a-checkbox>
        </a-col>
      </a-row> -->

      <!-- editor -->
      <!-- <a-row style="margin-top: 25px" type="flex">
        <a-col :span="3" class="form_title"> Биография: </a-col>
        <a-col v-show="langTab === 'oz'" :span="20">
          <editor
            :value="form.biography.oz"
            :initial-value="form.biography.oz"
            :init="{
              ...tinymce.default.props,
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip: 'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }"
            @input="form.biography.oz = editorChangeHandler(arguments[0])"
          />
        </a-col>
        <a-col v-show="langTab === 'uz'" :span="20">
          <editor
            :value="form.biography.uz"
            :initial-value="form.biography.uz"
            :init="{
              ...tinymce.default.props,
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip: 'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }"
            @input="form.biography.uz = editorChangeHandler(arguments[0])"
          />
        </a-col>
        <a-col v-show="langTab === 'ru'" :span="20">
          <editor
            :value="form.biography.ru"
            :initial-value="form.biography.ru"
            :init="{
              ...tinymce.default.props,
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip: 'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }"
            @input="form.biography.ru = editorChangeHandler(arguments[0])"
          />
        </a-col>
        <a-col v-show="langTab === 'qr'" :span="20">
          <editor
            :value="form.biography.qr"
            :initial-value="form.biography.qr"
            :init="{
              ...tinymce.default.props,
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip: 'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }"
            @input="form.biography.qr = editorChangeHandler(arguments[0])"
          />
        </a-col>
        <a-col v-show="langTab === 'en'" :span="20">
          <editor
            :value="form.biography.en"
            :initial-value="form.biography.en"
            :init="{
              ...tinymce.default.props,
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip: 'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }"
            @input="form.biography.en = editorChangeHandler(arguments[0])"
          />
        </a-col>
      </a-row> -->

      <!-- editor -->
      <!-- <a-row style="margin-top: 25px" type="flex">
        <a-col :span="3" class="form_title"> Обязанности: </a-col>
        <a-col v-show="langTab === 'oz'" :span="20">
          <editor
            :value="form.obligation.oz"
            :initial-value="form.obligation.oz"
            :init="{
              ...tinymce.default.props,
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip: 'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }"
            @input="form.obligation.oz = editorChangeHandler(arguments[0])"
          />
        </a-col>
        <a-col v-show="langTab === 'uz'" :span="20">
          <editor
            :value="form.obligation.uz"
            :initial-value="form.obligation.uz"
            :init="{
              ...tinymce.default.props,
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip: 'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }"
            @input="form.obligation.uz = editorChangeHandler(arguments[0])"
          />
        </a-col>
        <a-col v-show="langTab === 'ru'" :span="20">
          <editor
            :value="form.obligation.ru"
            :initial-value="form.obligation.ru"
            :init="{
              ...tinymce.default.props,
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip: 'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }"
            @input="form.obligation.ru = editorChangeHandler(arguments[0])"
          />
        </a-col>
        <a-col v-show="langTab === 'en'" :span="20">
          <editor
            :value="form.obligation.en"
            :initial-value="form.obligation.en"
            :init="{
              ...tinymce.default.props,
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip: 'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }"
            @input="form.obligation.en = editorChangeHandler(arguments[0])"
          />
        </a-col>
        <a-col v-show="langTab === 'qr'" :span="20">
          <editor
            :value="form.obligation.qr"
            :initial-value="form.obligation.qr"
            :init="{
              ...tinymce.default.props,
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip: 'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }"
            @input="form.obligation.qr = editorChangeHandler(arguments[0])"
          />
        </a-col>
      </a-row> -->

      <a-row class="form-buttons__action" style="margin-top: 20px" type="flex">
        <a-col :span="3" class="form_title" />
        <a-col>
          <a-button :disabled="pending" @click="$router.go(-1)">
            <a-icon type="close" />Отмена
          </a-button>

          <a-button
            :loading="pending"
            style="margin-left: 10px"
            type="primary"
            @click="submitStaffs"
          >
            <a-icon type="check" />
            Добавить
          </a-button>
        </a-col>
      </a-row>
    </a-col>
    <spoiler-modal
      v-show="!!spoiler.modalVisibility"
      v-model="spoiler"
      @ok="appendToEditor"
      @cancel="closeSpoilerModal"
    />
  </a-row>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
import spoiler from "@/mixins/spoiler.js"
const newsDefaultFields = () => ({
  full_name: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  nation: {
    uz: "",
    oz: "",
    ru: "",
    en: ""
  },
  address: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  biography: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  obligation: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  position: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  reception_days: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  email: "",
  phone_number: "",
  inner_number: "",
  birth_date: "",
  is_active: false,
  photo: null,
  order: null
  // language_support: {
  //   uz: false,
  //   oz: false,
  //   ru: false,
  //   en: false,
  //   qr: false
  // }
})

export default {
  name: "Staffs",

  components: {
    spoilerModal: () =>
      import("@/components/directory/modal-components/modal-spoiler")
    // editor: () => import("@tinymce/tinymce-vue")
  },

  mixins: [spoiler],

  data() {
    return {
      langTab: $langPrefix,
      form: { ...newsDefaultFields() },
      created: null,
      pending: false,
      loading: false,
      errors: {
        slug: ""
      },
      emptyTitle: false,
      modalVisible: false,
      photo: null,
      checkbox: null
    }
  },

  computed: {
    getKey() {
      return this.langTab
    },
    isFullNameNull() {
      return !!(
        !this.form.full_name.uz &&
        !this.form.full_name.oz &&
        !this.form.full_name.ru &&
        !this.form.full_name.en &&
        !this.form.full_name.qr
      )
    }
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.click()
    },
    checkChamber(e) {
      this.form.is_vacant = e.target.checked
      if (e.target.checked === true) {
        ;(this.form.full_name = {
          oz: "Бўш иш ўрни",
          uz: "Bo`sh ish o`rni",
          ru: "Ваканция",
          en: "Vacant",
          qr: "Ваканция"
        }),
          (this.form.email = "info@mininnovation.uz")
        this.form.phone_number = 998712033223
      } else {
        ;(this.form.full_name = {
          uz: "",
          oz: "",
          ru: "",
          en: "",
          qr: ""
        }),
          (this.form.email = "")
        this.form.phone_number = ""
      }
    },
    openStoreModal() {
      this.modalVisible = true
    },
    translitAction() {
      try {
        const from = this.langTab,
          to = this.langTab === "uz" ? "oz" : "uz",
          methodName = this.langTab === "uz" ? "toKirill" : "toLatin"

        if (this.form.title[from]) {
          this.form.title[to] = this[methodName](this.form.title[from])
        }

        if (this.form.obligation[from]) {
          this.form.obligation[to] = this[methodName](
            this.form.obligation[from]
          )
        }
        this.form.language_support[to] = true
      } catch (error) {
        this.$sentry.captureMessage(error)
      }
    },
    async submitStaffs() {
      if (this.pending) return

      this.pending = true
      ;(this.child_id && (this.form.menu = this.child_id)) ||
        (this.parent_id && (this.form.menu = this.parent_id))
      try {
        let newData = cloneDeep(this.form)

        newData = {
          ...newData,
          full_name_ru: newData.full_name.ru,
          full_name_uz: newData.full_name.uz,
          full_name_oz: newData.full_name.oz,
          full_name_en: newData.full_name.en,
          full_name_qr: newData.full_name.qr,
          nation_ru: newData.nation.ru,
          nation_uz: newData.nation.uz,
          nation_oz: newData.nation.oz,
          nation_en: newData.nation.en,
          nation_qr: newData.nation.qr,
          address_ru: newData.address.ru,
          address_uz: newData.address.uz,
          address_oz: newData.address.oz,
          address_en: newData.address.en,
          address_qr: newData.address.qr,
          biography_ru: newData.biography.ru,
          biography_uz: newData.biography.uz,
          biography_oz: newData.biography.oz,
          biography_en: newData.biography.en,
          biography_qr: newData.biography.qr,
          obligation_ru: newData.obligation.ru,
          obligation_uz: newData.obligation.uz,
          obligation_oz: newData.obligation.oz,
          obligation_en: newData.obligation.en,
          obligation_qr: newData.obligation.qr,
          position_ru: newData.position.ru,
          position_uz: newData.position.uz,
          position_oz: newData.position.oz,
          position_en: newData.position.en,
          position_qr: newData.position.qr,
          reception_days_ru: newData.reception_days.ru,
          reception_days_uz: newData.reception_days.uz,
          reception_days_oz: newData.reception_days.oz,
          reception_days_en: newData.reception_days.en,
          reception_days_qr: newData.reception_days.qr,
          type: 1
        }
        delete newData.full_name
        delete newData.position
        delete newData.nation
        delete newData.address
        delete newData.biography
        delete newData.obligation
        delete newData.reception_days

        if (newData.is_vacant === true) {
          delete newData.photo
        }

        console.log(newData)

        let f = new FormData()

        Object.keys(newData).forEach((key) => {
          newData[key] && f.append(key, newData[key])
          key === "is_active" && f.append(key, newData[key])
        })
        let res = await this.$api.post(
          "/admin/structure/leadership/create/?type=1",
          f
        )
        if (res.status >= 200) {
          this.$router.go(-1)
        }
        this.pending = false
      } catch (error) {
        console.error(error)
        this.$sentry.captureMessage(error)
      }
    },
    handleFile(e) {
      this.photo = URL.createObjectURL(e.target.files[0])
      this.form.photo = e.target.files[0]
    }
  }
}
</script>
<style>
.ant-modal {
  max-height: 600px;
}
.scroll-container {
  padding: 20px 0px;
}
.form_image > .deputy_image {
  width: 70px;
  height: 70px;
  border: 1px solid grey;
  border-radius: 40px;
  overflow: hidden;
  display: inline;
  margin-right: 30px;
}
.form_image > .deputy_image > img {
  width: 100%;
  height: 100%;
}
.form_image {
  margin: 10px 0;
  height: 70px;
  display: flex;
  align-items: center;
}

.inputNumber::-webkit-outer-spin-button,
.inputNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
